const _ = require('lodash');
const loadingClass = 'is-loading';
let $eventsListFilters = $('#js-events-filters'),
    $options = $('#js-events-filters-options'),
    $search = $('#js-event-filters-search'),
    type = '.js-event-filters-type';

$(document).on('change', 'input[name="cpi"]', function (e) {
    e.preventDefault();
    let $form = $(this).closest('form');
    let value = $(e.target).val();
    $form.find('input[name="cp"]').val(value);
});

// Listing
$(document).on('submit', '#js-events-list-form', function (e) {
    e.preventDefault();
    update_events_list($(this));
    $('body, html').removeClass('is-no-scroll');
});

$(document).on('change', '.js-event-list-filter', function (e) {
    e.preventDefault();
    let $form = $('#js-events-list-form');
    $form.find('input[name="cp"]').val(1);
    $form.trigger('submit');
});

$(document).on('click', '.js-event-list-submit', function (e) {
    e.preventDefault();
    let $form = $('#js-events-list-form');
    $form.find('input[name="cp"]').val(1);
    $form.trigger('submit');
});

$(document).on('click', '.js-calendar-change-month', function (e) {
    e.preventDefault();
    let $form = $('#js-events-list-form');
    $form.find('input[name="cp"]').val(1);
    $form
        .find('input[name="change_to_month"]')
        .val($(this).attr('data-change-to-month'));
    $form.trigger('submit');
});

function update_events_list($form) {
    let formData = $form.serialize();
    let $eventsList = $('#js-events-list');
    let $lastMonth = $('.js-calendar-last-month');
    let $currentMonth = $('.js-calendar-current-month');
    let $nextMonth = $('.js-calendar-next-month');

    $.ajax({
        type: 'post',
        url: ajax.url,
        data: formData,
        beforeSend: function () {
            $form.addClass(loadingClass);
            console.log('eveents', $form);
            window.scroll({
                top: $form.offset().top - (window.innerWidth > 900 ? 250 : 30),
                behavior: 'smooth',
            });
        },
        success: function (response) {
            history.pushState({}, null, response.url);
            $eventsList.html(response.list);

            $lastMonth.attr('data-change-to-month', response.last_month);
            $currentMonth.html(response.current_month);
            $nextMonth.attr('data-change-to-month', response.next_month);

            $form.removeClass(loadingClass);
        },
    });
}

// Typ wyszukiwania - miasto lub lokal
$(type).on('change', function () {
    let typeChecked = $(this).val();
    $.ajax({
        type: 'post',
        url: ajax.url,
        data: {
            action: 'mda_event_filters_type',
            type: typeChecked,
        },
        beforeSend: function () {
            $options.html('');
            $eventsListFilters.find('.load-component').addClass(loadingClass);
            $search.val('');
        },
        success: function (response) {
            $options.html(response.list);
            $eventsListFilters
                .find('.load-component')
                .removeClass(loadingClass);
            $search.attr('placeholder', response.placeholder);
        },
    });
});

// Wyszukiwanie po nazwie
$search.on(
    'keyup',
    _.debounce(function () {
        let s = $(this).val();
        let typeChecked = $(`${type}:checked`).val();
        $.ajax({
            type: 'post',
            url: ajax.url,
            data: {
                action: 'mda_event_filters_search',
                type: typeChecked,
                s: s,
            },
            beforeSend: function () {
                $eventsListFilters
                    .find('.load-component')
                    .addClass(loadingClass);
            },
            success: function (response) {
                $options.html('');
                $options.html(response.list);
                $eventsListFilters
                    .find('.load-component')
                    .removeClass(loadingClass);
            },
        });
    }, 500)
);
